import React, { Component } from "react";
import '../Css/ContentPage.css';
import homepage from '../Json/HomePage.json';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faSoundcloud, faTiktok, faYoutube } from '@fortawesome/free-brands-svg-icons';

export class ContentPage extends Component {

    render() {
        const { language } = this.props;
        const data = homepage[language];
        if (!data) {
            return <div>Données non disponibles pour la langue sélectionnée : {language}</div>;
        }

        const { titlebutton, description } = data;
        return (
            <div className="page">
                <LeftChild titlebutton={titlebutton} />
                <RightSide description={description} />

                {/*  */}
            </div>
        );
    }
}
/*Partie gauche du site (Nom de l'artiste, réseaux sociaux version pc et bouton Ecouter) */
export class LeftChild extends Component {
    render() {
        const { titlebutton } = this.props;
        return <div id="left">
            <div className="wrapper">
                <div className="networks">
                    <div className="content">
                        <h1>THOMAS LERIAN</h1>
                        <div className="Social-icons">
                            <a href="https://www.instagram.com/thomaslerianmusic" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faInstagram} size="3x" />
                            </a>
                            <a href="https://www.soundcloud.com/thomas-lerian-s" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faSoundcloud} size="3x" />
                            </a>
                            <a href="https://www.youtube.com/channel/UCC7M01R05jvpKjWBOC5isYw" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faYoutube} size="3x" />
                            </a>
                            <a href="https://www.tiktok.com/@thomaslerian" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faTiktok} size="3x" />
                            </a>
                        </div>
                        <a href="https://www.soundcloud.com/thomas-lerian-s" target="_blank" rel="noopener noreferrer" className="Content-button">
                            <span className="text">{titlebutton}</span><span>SoundCloud</span></a>
                    </div>
                    <hr />
                </div>
            </div>
        </div>
    }
}

/*Partie droite du site (texte, presskit, boutons, reseaux sociaux version tel)*/
export class RightSide extends Component {
    render() {
        const { description } = this.props;

        return <div id="right">
            <div className="Content-textbox">
                <p dangerouslySetInnerHTML={{ __html: description }}></p>
            </div>
            <div className="presskit-container">
                <h1 className=" Content-presskit">PRESSKIT</h1>
                <hr />
                <div className="presskit-section">
                    <a href="https://www.dropbox.com/scl/fo/2h1vhn0ihulyasx5ckuc6/h?rlkey=g1hykq50a7ujo2ekjd8ymu19j&st=ewt9791g&dl=0" target="_blank" rel="noopener noreferrer" className="Presskit-button"><span className="text">LOGO</span></a>
                    <a href="https://www.dropbox.com/scl/fo/5hrevggq9ksgs6ftiw43a/AABdkP0cVoRZvFz3ISiN1Es?rlkey=b3l3mpp0c27t32t4ufuso9iq7&st=l7n805a5&dl=0" target="_blank" rel="noopener noreferrer" className="Presskit-button" ><span className="text">PRESS SHOT</span></a>
                    <a href="https://www.dropbox.com/scl/fi/w5lo661cecw43yhuwj0jb/IMG_4856.jpg?rlkey=e45v5e99owj3ufe4y2iaycdnp&st=bmj2nx8x&dl=0" target="_blank" rel="noopener noreferrer" className="Presskit-button" ><span className="text">RIDER</span></a>
                </div>
            </div>
            <div className="div-social-tel">
                <div className="Social-footer-tel">
                    <a href="https://www.youtube.com/channel/UCC7M01R05jvpKjWBOC5isYw" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faYoutube} size="2x" />
                    </a>
                    <a href="https://www.soundcloud.com/thomas-lerian-s" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faSoundcloud} size="2x" />
                    </a>
                    <a href="https://www.instagram.com/thomaslerianmusic" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faInstagram} size="2x" />
                    </a>
                    <a href="https://www.tiktok.com/@thomaslerian" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faTiktok} size="2x" />
                    </a>
                </div>
            </div>
        </div>
    }
}

export default ContentPage;