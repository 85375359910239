import React, { useState, useEffect } from "react";
import '../Css/Logo.css';


export const Logo = () => {
    const [showButton, setShowButton] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.pageYOffset > 100) {
                setShowButton(true);
            } else {
                setShowButton(false);
            }
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <div className="header">
                {showButton && (
                    <img className="logo" src="/vague.png" alt="Logo" onClick={scrollToTop}/>
                    
                )}        
        </div>
    );
};

export default Logo;
