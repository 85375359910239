import './App.css';
import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Menu from './Frames/Menu.js';
import { Logo } from './Components/Logo';
import {Helmet} from "react-helmet";


function App() {
  const [language, setLanguage] = useState('fr'); 

  const switchLanguage = (lang) => {
    setLanguage(lang);
  };

  return (
    <div className="App">
      <Helmet>
          <meta charSet="utf-8" />
          <title>Thomas Lerian</title>
          <link rel="canonical" href="https://thomaslerian.com" />
          <meta name="description" content="Thomas Lerian, DJ Producteur de 25 ans reconnu pour ses sets complets et uniques se démarque depuis quelques années sur la scène européenne. Copyright 2024" />
      </Helmet> 
      <Router>
      <Logo className="logo" />
        <Routes>
          <Route exact path="/" element={<Menu language={language}/>} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
