import React, { Component } from "react";
import '../Css/Language.css';

export class Language extends Component{
  render() {
    const { language } = this.props;
    return (
        <div className="language-container">
          <h3 className="langFR"onClick={() => this.props.switchLanguage('fr')}>FR</h3>
          <h3>&nbsp;|&nbsp;</h3>
          <h3 className="langEN" onClick={() => this.props.switchLanguage('en')}>EN</h3>  
        </div>
    );
  }
}
