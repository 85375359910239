import React, { Component } from "react";
import '../Css/HomeBanner.css';
import { motion } from 'framer-motion';
import home from '../Json/HomeBanner.json';
import { HiArrowDownCircle } from "react-icons/hi2";

export class HomeBanner extends Component {
    constructor(props) {
        super(props);
        this.videoRef = React.createRef();
        this.state = {
            videoStarted: false,
            isPlaying: false
        };
    }

    handleScrollToMarquee = () => {
        const marqueeElement = document.getElementById('marquee');
        if (marqueeElement) {
            marqueeElement.scrollIntoView({ behavior: 'smooth' });
        }
    };

    render() {
        const { language } = this.props;
        const data = home[language];

        if (!data) {
            return <div>Données non disponibles pour la langue sélectionnée : {language}</div>;
        }

        const { text } = data;
        return (
            <div className="Banner">
                <div className="video-banner">
                    <video 
                        ref={this.videoRef}
                        id="bgvid" 
                        src="arcrecap.mp4" 
                        alt="Bannière de DJ"
                        autoPlay
                        loop 
                        muted 
                        playsInline
                        className={this.state.videoStarted ? 'fade-in' : ''}
                    />
                </div>
                <motion.div 
                    animate={{ y: [-5, 5, -5], transition: { duration: 5, repeat: Infinity } }}
                >
                    <img className="midlogo" src="LOGO 2 WHITE.png" alt="Logo" />
                </motion.div>
                <div className="BannerText" onClick={this.handleScrollToMarquee}>
                    <motion.div
                        animate={{ y: [-5, 5, -5], transition: { duration: 5, repeat: Infinity } }}
                    >
                        <HiArrowDownCircle />
                    </motion.div>
                </div>
            </div>
        );
    }
}

export default HomeBanner;
