import React, { useState } from 'react';
import Marquee from 'react-fast-marquee';
import '../Css/Marquee.css';

function Marq() {
  const [speed, setSpeed] = useState(60); // Vitesse par défaut



  return (
    <section id="marquee">
    <div className="marquee-container">
      <div className="marquee-div">
        <Marquee direction="left" gradient={false} speed={speed}>
          <span
            className="marquee-item"
          >
            ARC CANNES
          </span>
          &nbsp;
          <span
            className="marquee-item"
          >
            MANKO PARIS
          </span>
          &nbsp;
          <span
            className="marquee-item"
          >
            CASA AMOR
          </span>
          &nbsp;
          <span
            className="marquee-item"
          >
            VIP ROOM PARIS
          </span>
          &nbsp;
          <span
            className="marquee-item"
          >
            ARC PARIS
          </span>
          &nbsp;
          <span
            className="marquee-item"
          >
            BOUM BOUM
          </span>
        </Marquee>
        <Marquee direction="right" gradient={false} speed={speed}>
          <span
            className="marquee-item"
          >
            VIP ROOM PARIS
          </span>
          &nbsp;
          <span
            className="marquee-item"
          >
            ARC PARIS
          </span>
          &nbsp;
          <span
            className="marquee-item"
          >
            BOUM BOUM
          </span>
        </Marquee>
      </div>
    </div>
    </section>
  );
}

export default Marq;