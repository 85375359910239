import React, { useState } from "react";
import '../Css/Menu.css';
import HomeBanner from '../Components/HomeBanner';
import {Language} from '../Components/Language';
import {ContentPage} from '../Components/ContentPage';
import Marquee from '../Components/Marquee';

function Menu() {
    const [language, setLanguage] = useState('fr');
     const switchLanguage = (lang) => {
        setLanguage(lang);
    };
        return (
            <div className="Menu">
                <HomeBanner language={language}/>
                <Marquee/>
                <ContentPage language={language}/>
                <Language language={language} switchLanguage={switchLanguage} />
            </div>
        );
    }
export default Menu;
